.plans-container{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
    margin-top: -4rem;
    padding: 0 2rem;
}

.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

/*  This selector looks for any element with the class .plan that is the second child of its parent element. */
/* this choose the second child in a parent wich its child is plan */
/* .plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
} */

/* This selector looks for the second child of the .plans element, regardless of the child's class or type. */
/* this choose the second child of plans */

.plans>:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}

.plan>:nth-child(1){
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

/* i aded this for more beauty! */
.plans>:nth-child(2)>:nth-child(1){
    fill: white;
}

.plans>:nth-child(2)>:nth-child(6){
    color: var(--orange);
}

/* an alternative way to choose the plan.icon: */
/* .plan>svg */

.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}

.plan>:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img{
    width: 1rem;
}

.plans-blur1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}

.plans-blur2{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 0;
}

/* .plans-blur3{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 20rem;
    left: 20rem;
    filter: blur(120px);
} */

@media screen and (max-width: 768px) {
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }
}



